import '../../../../../modules/system/assets/js/framework';
import '../../../../../modules/system/assets/js/framework.extras.js';
global.$ = global.jQuery = require('jquery');

import hljs from 'highlight.js';
document.addEventListener('DOMContentLoaded', (event) => {
    document.querySelectorAll('pre code').forEach((el) => {

        hljs.highlightElement(el);
    });
});